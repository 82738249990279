/* You can add global styles to this file, and also import other style files */

@import 'src/styles/reset.scss';
@import 'src/styles/base.scss';
@import 'src/styles/style.scss';
@import 'src/styles/dark.scss';

body {
  --font-family: 'Poppins', sans-serif;
  --primary-color: #0e8166;
  --primary-hover-color: #17cda2;
  --primary-text-color: #0e8166;
  --secondary-color: #119fbe;
  --background-color: #e7e7e7;
  --box-background-color: #f3f3f3;
  --match-background-color: #d1d1d1;
  --text-color: black;
  --default-box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.1), 0 0 0 1px hsla(230, 13%, 9%, 0.075),
  0 0.3px 0.4px hsla(230, 13%, 9%, 0.02), 0 0.9px 1.5px hsla(230, 13%, 9%, 0.045),
  0 3.5px 6px hsla(230, 13%, 9%, 0.09);
  --dropdown-background-color: white;
  --dropdown-hover-background-color: #e1e1e1;

  --empty-slot-win-background: rgba(12, 17, 59, 0.25);
  --empty-slot-loss-background: rgba(61, 3, 3, 0.25);
  --empty-slot-opponent-background: rgba(50, 50, 50, 0.25);

  background-color: var(--background-color);
  color: var(--text-color);
}
