* {
  box-sizing: border-box;
}

html,
body,
app-root {
  height: 100%;
}

app-root {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
  margin-top: 0;
}

app-footer {
  flex-shrink: 0;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}
