body.dark {
  --primary-hover-color: #13a884;
  --primary-text-color: #17cda2;
  --background-color: #0d1117;
  --box-background-color: #161b22;
  --match-background-color: #090c10;
  --text-color: #e6edf3;
  --dropdown-background-color: #1c242f;
  --dropdown-hover-background-color: #0d1117;

  --empty-slot-win-background: rgba(18, 21, 43, 0.7);
  --empty-slot-loss-background: rgba(61, 3, 3, 0.7);
}
