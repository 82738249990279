body {
  font-family: var(--font-family);
  line-height: 1.5rem;
}

body:has(.modal) {
  overflow: hidden;
}

.page-container {
  width: 70%;
  margin: auto;
  max-width: 1250px;

  &.lookback {
    background: var(--box-background-color);
    text-align: center;
    border-radius: 10px;
    padding: 30px 10px 75px;
  }
}

h1 {
  margin: 15px 0 35px;
  font-size: 3rem;
  font-weight: bold;
}

.p-tooltip-wide > .p-tooltip-text {
  width: 250px !important;
  text-align: center;
}

.p-overlaypanel, .p-tooltip-text {
  background-color: var(--background-color) !important;
  border-color: var(--background-color) !important;
  color: var(--text-color) !important;
  padding: 10px !important;
}

.p-overlaypanel:before, .p-overlaypanel:after {
  border-bottom-color: var(--background-color) !important;
}

.p-overlaypanel-flipped:before, .p-overlaypanel-flipped:after {
  border-top-color: var(--background-color) !important;
  border-bottom-color: transparent !important;
}

a {
  text-decoration: none;
}

a:not(.button) {
  color: var(--primary-text-color);

  &:hover {
    text-decoration: underline;
  }
}

a.button {
  background: var(--primary-color);
  padding: 8px 20px;
  display: block;
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: var(--default-box-shadow);

  &:hover {
    background: var(--primary-hover-color);
  }
}

/* Form Styling */
form {
  margin-top: 25px;

  .form-group {
    width: 60%;
    text-align: left;
    margin: 15px auto;

    label {
      display: block;
      margin-bottom: 5px;
    }

    input,
    select,
    .ng-select .ng-select-container {
      width: 100% !important;
      font-size: 1rem;
      background-color: var(--background-color);
      color: var(--text-color);
      font-family: var(--font-family);
      border: none !important;
      border-radius: 8px !important;

      &::placeholder {
        color: var(--text-color);
        opacity: .5;
      }
    }

    input.form-control,
    select.form-control,
    .ng-select .ng-value-container {
      padding: 20px !important;
    }

    .ng-select {
      &.ng-select-single .ng-input {
        padding: 20px !important;
        top: 0 !important;
      }

      .ng-select-container {
        height: auto !important;
      }

      .ng-value {
        border: none !important;
        background: var(--primary-color) !important;
        padding: 5px 10px !important;
        margin-right: 10px !important;
        border-radius: 5px !important;
        color: white !important;
        display: flex;
        flex-direction: row-reverse;

        .ng-value-icon {
          &.left {
            border-right: none !important;
          }

          &:hover {
            background: none !important;
          }
        }
      }
    }
  }

  button[type='submit'] {
    background: var(--primary-color);
    padding: 15px;
    width: 60%;
    border-radius: 4px;
    margin-top: 15px;
    color: white;
    box-shadow: var(--default-box-shadow);
    border: none;
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      background: var(--primary-hover-color);
    }
  }
}

strong {
  font-weight: bold;
  color: var(--primary-text-color);
}

.secondary-highlight {
  color: var(--secondary-color);
  font-weight: bold;
}

.note {
  margin-top: 25px;
  font-size: .75rem;
  color: var(--primary-text-color);
  opacity: .75;
}

button.primary-button, .primary-button button {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: white;
  box-shadow: none !important;

  &:enabled:focus {
    background: var(--primary-color);
    border-color: var(--primary-color);
    color: white;
  }

  &:enabled:hover {
    background: var(--primary-hover-color);
    border-color: var(--primary-hover-color);
    color: white;
  }
}

.p-dialog {
  .p-dialog-header, .p-dialog-content {
    background: var(--background-color) !important;
  }
}

@media (max-width: 1500px) {
  .page-container {
    width: 85%;
  }
}

@media (max-width: 1250px) {
  .page-container {
    width: 95%;
  }
}

@media (max-width: 1100px) {
  .page-container {
    width: 85%;
  }
}

@media (max-width: 1000px) {
  .page-container {
    width: 90%;
  }

  form {
    .form-group {
      width: 75%;
    }
  }
}

@media (max-width: 950px) {
  .page-container {
    width: 95%;
  }
}

@media (max-width: 900px) {
  .page-container {
    width: 75%;
  }
}

@media (max-width: 850px) {
  .page-container {
    width: 80%;
  }
}

@media (max-width: 800px) {
  .page-container {
    width: 85%;
  }
}

@media (max-width: 750px) {
  .page-container {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .page-container {
    width: 95%;
  }

  form {
    .form-group {
      width: 95%;
    }
  }
}
